.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: 700;
  }

  button {
    background: var(--orange);

    width: 20.5rem;
    height: 8.75vh;

    margin: 1rem;

    font-size: 1rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: var(--white);

    border: 1px solid var(--orange);
    box-sizing: border-box;
    border-radius: 4px;
  }

  &Tembici {
    @extend .homeContainer;
    button {
      background: var(--pink);
      border: 1px solid var(--pink);
    }
  }
}
