@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --white_dark: #eeedf1;
  --orange: #ec7000;
  --gray: #535161;
  --pink: #e6007e;
  --blue: #003399;
}

body {
  background: var(--white);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Open Sans', sans-serif;
    color: #535161;
    text-align: center;
    margin: 0.5rem;
  }

  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.75rem;
    width: 16.5rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 16.5rem;
  }

  h4 {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 18.5rem;
  }
}

button {
  cursor: pointer;
}
