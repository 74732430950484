.circularProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.questionsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 85vw;
  height: 85vh;

  border: 1px solid var(--white_dark);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  h6 {
    font-family: 'Open Sans';
    font-weight: 400;
    letter-spacing: -0.4000000059604645px;
  }

  button {
    width: 100%;
    max-width: 270px;
    background: var(--white);

    width: 20.5rem;
    min-height: 8.75vh;
    max-height: max-content;

    margin: 1rem;

    font-size: 1rem;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: var(--gray);

    border: 1px solid var(--gray);
    box-sizing: border-box;
    border-radius: 4px;
  }

  &Tembici {
    @extend .questionsBox;
  }
}

.topBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}